import styled, { css } from "styled-components";

export const ColumnStyle = css`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;

  & > p {
    text-align: center;
  }
  & > * {
    margin: 0;
  }
`;

export const UnderlinedText = css`
  border-bottom: 1px solid #ddd;
  padding-bottom: 20px;
`;

export const Page = styled.div<{ $image?: string }>`
  ${ColumnStyle}
  flex: 1;
  margin-top: 55px;
  view-transition-name: content;
  padding: 5%;

  ${({ $image }) =>
    $image
      ? `padding-top: 30vh;
        @media (min-width: 768px) {
          padding-top: 45svh;
        }
        & > div {
          z-index: 1;
        }
        position: relative;
        &::before,
        &::after {
          z-index: 0;
          content: "";
          position: absolute;
          width: 100%;
        }
        &::before {
          height: 33svh;
          @media (min-width: 768px) {
            height: 50svh;
          }
          top: 0;
          background-image: url("${$image}");
          background-size: cover;
          background-position: center;
          background-color: #cdcff3;
        }
        &::after {
          top: 33svh;
          @media (min-width: 768px) {
            top: 50vh;
          }
          bottom: 0;
          background-color: #cdcff3;
        }`
      : `background-color: #cdcff3;
        @media (min-width: 768px) {
          padding: 50px;
        }`}
`;
