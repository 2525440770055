import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { ButtonStyle } from "./Button";

export const Nav = styled.nav<{ open?: boolean }>`
  transition: transform 0.5s ease-in-out;
  position: absolute;
  left: 0;
  right: 0;
  @media (max-width: 768px) {
    position: fixed;
    top: 55px;
    bottom: 0;
    left: 0;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 75%;
    transform: ${({ open }) =>
      open ? "translateX(0)" : "translateX(calc(-100% - 10px))"};
    & > ul > li {
      font-size: 1.2em;
      li {
        font-size: initial;
      }
    }
  }
`;

export const NavList = styled.ul`
  justify-content: center;
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  @media (max-width: 768px) {
    flex-direction: column;
    overflow: hidden;
  }
`;

export const NavLinkStyled = styled(NavLink)`
  text-decoration: none;
  color: black;
  &.active {
    color: #9c77ff;
    pointer-events: none;
  }
  &.button {
    ${ButtonStyle}
    margin-left: 20px;
    z-index: 2;
  }
  &:hover:not(.active) {
    svg {
      filter: drop-shadow(2px 3px 2px rgb(0 0 0 / 0.4));
    }
  }
`;

export const DropdownMenu = styled.div`
  transition: 0.3s ease-in-out;
  @media (min-width: 768px) {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: -20px;
    top: 30px;
    background-color: #f9f9f9;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    overflow: hidden;
    ${NavList} {
      flex-direction: column;
    }
    ${NavLinkStyled} {
      line-height: 25px;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
      &:hover {
        background-color: #f1f1f1;
      }
    }
  }
`;

export const NavItem = styled.li`
  line-height: 50px;
  margin: 0 1rem;
  position: relative;
  cursor: pointer;
  ${DropdownMenu} {
    li {
      line-height: 35px;
    }
  }
  @media (min-width: 768px) {
    ${DropdownMenu} {
      li {
        margin: 0;
        line-height: unset;
      }
    }
    &:hover ${DropdownMenu} {
      opacity: 1;
      visibility: visible;
      transform: translateY(20px);
    }
  }
`;
