import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";

export const ButtonStyle = css`
  text-decoration: none;
  background-color: white;
  color: black;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  border: 1px solid #ddd;

  &:hover:not(.active),
  &:focus:not(.active) {
    background-color: #cdcff3;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }

  &:active,
  &.active {
    background-color: #9c77ff;
    color: white;
  }
`;

export const NavButton = styled(NavLink)`
  ${ButtonStyle}
`;

export const Button = styled.button`
  ${ButtonStyle}
`;
