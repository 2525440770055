import styled, { css } from "styled-components";

const Icon = styled.div<{ open?: boolean }>`
  z-index: 2;
  display: none;
  cursor: pointer;
  width: 35px;
  height: 30px;

  span,
  &::before,
  &::after {
    content: "";
    background-color: black;
    height: 2px;
    transition: 0.4s;
  }
  ${({ open }) =>
    open &&
    css`
      &::before {
        transform: rotate(45deg) translate(7px, 7px);
      }
      &::after {
        transform: rotate(-45deg) translate(8px, -7px);
      }
      span {
        opacity: 0;
      }
    `}
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
`;

const Burger: React.FC<{ open: boolean; onClick: () => void }> = ({
  open,
  onClick,
}) => (
  <Icon open={open} onClick={onClick}>
    <span></span>
  </Icon>
);

export default Burger;
