import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  useLocation,
} from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { useEffect } from "react";

const router = createBrowserRouter([
  {
    path: "/",
    Component: () => {
      const { pathname } = useLocation();
      useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }, [pathname]);

      return (
        <>
          <Header isHome={pathname === "/"} />
          <Outlet />
          <Footer />
        </>
      );
    },
    children: [
      {
        index: true,
        lazy: () => import("./pages/Home"),
      },
      {
        path: "/termin-buchen",
        lazy: () => import("./pages/BookAppointment"),
      },
      {
        path: "/angebote/klassische-massage",
        lazy: () => import("./pages/ClassicMassage"),
      },
      {
        path: "/angebote/fussreflexzonenmassage",
        lazy: () => import("./pages/FootReflexology"),
      },
      {
        path: "/angebote/dorntherapie",
        lazy: () => import("./pages/DornTherapy"),
      },
      {
        path: "/angebote/naturheilkunde",
        lazy: () => import("./pages/Naturopathy"),
      },
      {
        path: "/ueber-mich",
        lazy: () => import("./pages/AboutMe"),
      },
      {
        path: "/kontakt",
        lazy: () => import("./pages/Contact"),
      },
      {
        path: "/preise",
        lazy: () => import("./pages/Prices"),
      },
      {
        path: "/impressum",
        lazy: () => import("./pages/LegalNotice"),
      },
      {
        path: "/datenschutzerklaerung",
        lazy: () => import("./pages/PrivacyPolicy"),
      },
    ],
  },
]);

const App = () => (
  <RouterProvider router={router} future={{ v7_startTransition: true }} />
);
export default App;
